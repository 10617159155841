

// ************
// Prevent scrollytelling issues
// history.scrollRestoration = 'auto'
// window.onbeforeunload = function () {
//   window.scrollTo(0, 0)
// }


// **********************
async function loadCSS() {
  import("../sass/7-bundles/index.scss");
}

async function loadExperience() {
  import('../experience/experience.js')
}

async function loadAndroid() {
  import('../scripts/android.js')
}

async function loadTree() {
  import('../experience2/tree.js')
}

async function loadIndexAnim() {
  import('../scripts/indexAnim.js')
}

async function loadSidenav() {
  import('../scripts/sidenav.js')
}

async function checkFontsReady() {
  while (!document.fonts.ready) {
    console.log('not ready')
    await new Promise(resolve => {
      setTimeout((resolve), 100)
    })
  }
  document.fonts.onloadingdone = () => {
    console.log('fonts ready')
  }
  // if (document.fonts.ready) {
  //   console.log('fonts ready')
  // } else {
  //   console.log('fonts not ready')
  // }
  return true
}

async function loadForm() {
  import('../scripts/form.js')
}

// async function checkFontsReady() {
//   await document.fonts.onloadingdone = () => {

//   }
// }

// document.fonts.ready.then(() => {
// })
// ***********************
window.addEventListener('load', async function loadModules() {
  await loadCSS()
  await checkFontsReady()
  await loadExperience()
  if (navigator.userAgent.includes('Android')) {
    await loadAndroid()
  } else {
    await loadTree()
  }
  // await loadTree()
  await loadIndexAnim()
  await loadSidenav()
  await loadForm()
  // if (navigator.userAgent.includes('Android') == false) {
  //   await loadExperience()
  // }
  // if (navigator.userAgent.includes('Android') == false) {
  //   await loadTree()
  // }

})